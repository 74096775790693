export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  server: 'https://test.marketplace.faithdriveninvestor.org/api',
  authServer: 'https://test.groups.faithdrivenentrepreneur.org/api-auth',
  secure: false,
  placesApiKey: 'AIzaSyAP_QSIbrgr7186CebjAOsFmgr9mQ45o7o',
  recaptchaApiKey: '6LcmW9oaAAAAAH7CduvrhqLxMmtGeRC7JHR9TQLr',
  firebaseConfig: {
    apiKey: 'AIzaSyBTq-6XAYRvyFz554F9blkWoGZy0KCf-is',
    authDomain: 'faith-driven.firebaseapp.com',
    projectId: 'faith-driven',
    storageBucket: 'faith-driven.appspot.com',
    messagingSenderId: '341019504498',
    appId: '1:341019504498:web:1287ecde162a54206937eb',
    measurementId: 'G-YLB2JY55PL',
  },
};
